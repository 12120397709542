import { useQuery } from 'react-query';

import { getActivityQuestions } from 'App/services/AMSService';
import { errorHandler } from 'App/utils';

export function getActivityQuestionsQueryKey(activityId) {
    return ['activityQuestions', activityId];
}

function useFetchActivityQuestions(activityId) {
    return useQuery(getActivityQuestionsQueryKey(activityId), () => getActivityQuestions(activityId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchActivityQuestions;
