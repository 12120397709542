import { cx } from '@emotion/css';
import { clone, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import asFormField from './asFormField';

export const TextareaInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    placeholder,
    disabled,
    readOnly,
    maxLength,
    rows,
    minRows,
    maxRows,
    onClick,
    debounce: enableDebounce,
    ...rest
}) => {
    // Keep internal state to debounce & optimize rerenders
    const [internalValue, setInternalValue] = useState(value);
    // If external state updated, then update internal state
    useEffect(() => setInternalValue(value), [value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(debounce(onChange, 200), [onChange]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setInternalValue(newValue);
        const changeFn = enableDebounce ? debouncedOnChange : onChange;
        changeFn(clone(e));
    };

    return (
        <TextareaAutosize
            {...rest}
            id={name}
            value={internalValue}
            onChange={handleChange}
            onBlur={onBlur}
            className={cx('form-control', className)}
            style={style}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxLength}
            minRows={rows || minRows}
            maxRows={rows || maxRows}
            onClick={onClick}
        />
    );
};

TextareaInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
    onClick: PropTypes.func,
    debounce: PropTypes.bool,
};

TextareaInput.defaultProps = {
    value: '',
    onBlur: null,
    className: '',
    style: null,

    placeholder: '',
    disabled: false,
    readOnly: false,
    maxLength: null,
    rows: null,
    minRows: 2,
    maxRows: undefined,
    onClick: null,
    debounce: false,
};

export const TextareaField = asFormField({ WrappedComponent: TextareaInput });
