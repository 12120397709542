import { useQuery } from 'react-query';

import { getFieldOrder } from 'App/services/QueueManagerService';

function useFetchFieldOrder(companyId) {
    return useQuery(['queueManager', 'fieldOrder', companyId], () => getFieldOrder(companyId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchFieldOrder;
