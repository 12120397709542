import styled from '@emotion/styled';
import { Formik } from 'formik';
import React, { useMemo } from 'react';

import FlexCenter from 'App/components/styles/FlexCenter';
import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import { useUserContext } from 'App/contexts/UserContext';
import QueueManagerTable from './components/QueueManagerTable';
import { fieldOptionsLookup } from './constants';
import useFetchFieldOrder from './hooks/useFetchFieldOrder';

const QueueManagerStyle = styled.div`
    padding: 20px;
`;

const QueueManager = () => {
    const user = useUserContext();
    const { data: fieldOrders, isLoading } = useFetchFieldOrder(user.active_company.ID);

    const initialValues = useMemo(() => {
        return {
            fieldOrders: fieldOrders?.map((fieldOrder) => ({
                ...fieldOrder,
                field_order: fieldOrder.field_order.map((branchFieldOrder) => ({
                    ...branchFieldOrder,
                    field_selected: {
                        value: branchFieldOrder.field_selected,
                        label: fieldOptionsLookup[branchFieldOrder.field_selected],
                    },
                })),
            })),
            selectedBranch: {
                division_name: user.active_branch.Name,
                division_id: user.active_branch.ID,
            },
        };
    }, [fieldOrders, user.active_branch.ID, user.active_branch.Name]);

    if (isLoading) {
        return (
            <FlexCenter style={{ flexGrow: 1 }}>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    return (
        <QueueManagerStyle>
            <PageTitle style={{ padding: 0 }}>Queue Manager</PageTitle>

            <Formik initialValues={initialValues}>
                {({ values }) => (
                    <>
                        <h4 style={{ marginTop: 30, marginBottom: 30 }}>{values.selectedBranch.division_name}</h4>

                        <QueueManagerTable />
                    </>
                )}
            </Formik>
        </QueueManagerStyle>
    );
};

export default QueueManager;
