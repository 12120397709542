import dayjs from 'dayjs';

function getInitialFormValueForQuestion(question) {
    if (question.type === 'date' && question.response) {
        return dayjs(question.response).toDate();
    }
    if (question.type === 'select') {
        return question.answers[question.response];
    }
    return question.response;
}

function convertAssessmentQuestionToView(question) {
    return {
        ...question,
        response: getInitialFormValueForQuestion(question),
    };
}

export default convertAssessmentQuestionToView;
