import React, { useState } from 'react';
import { Row, Col, FormControl, Button, Modal } from 'react-bootstrap';
import { useUserContext } from '@/app/contexts/UserContext';
import Main from '../styles/Main/Main';
import * as R from 'ramda';
import FormButtonGroup from '@/app/components/styles/FormButtonGroup';
import LaddaButton from '@/app/components/LaddaButton';
import useSendMassTextingMutation from '@/app/hooks/useSendMassTextingMutation';
import './PatientMessagingStyles.scss';

import SearchSubscribedPatients from './SearchSubscribedPatients';

const propTypes = {};
const defaultProps = {};

function MassTexting() {
    const [message, setMessageValue] = useState('');
    const user = useUserContext();
    const pharmacyName = R.path(['active_branch', 'Name'], user);
    const [show, setShow] = useState(false);
    const [patientArr, setPatientArr] = useState([]);
    const { mutateAsync, isLoading } = useSendMassTextingMutation();

    const preview = () => {
        return (
            <>
                <p>Preview:</p>
                <p>
                    {pharmacyName}: {`${message} Text UNSUBSCRIBE to unsubscribe; HELP for help.`}
                </p>
            </>
        );
    };

    const cancel = () => {
        setShow(false);
    };

    const reviewSubmit = () => {
        if (message.length > 14 && message.length < 251) {
            setShow(true);
        }
    };

    const send = async () => {
        await mutateAsync({ message, selectedPatients: patientArr });
        setMessageValue('');
        cancel();
    };

    return (
        <Main fluid className="mass-texting">
            <h1>Send Mass Text</h1>
            <Row>
                <span>Use this form to send mass texts to patients opted-in to patient messaging.</span>
            </Row>
            <Row className="disclaimer">
                <span>
                    Disclaimer: SMS is unsecure and unencrypted. DO NOT SEND PHI. Do not include patient-specific
                    information. These texts should be used for informational purposes only (example use cases: pharmacy
                    closures, holidays, weather events).
                </span>
            </Row>
            <div className="form">
                <Row>
                    <Col>
                        <span>
                            <strong>
                                Your Message <span className="required">*</span>
                            </strong>
                        </span>
                        <span className="float-right">{message.length}/250</span>
                    </Col>
                    <FormControl
                        value={message}
                        onChange={(e) => setMessageValue(e.target.value)}
                        componentClass="textarea"
                        rows={4}
                        placeholder="Enter your message"
                        maxLength={250}
                        role="textbox"
                        aria-label="Enter your message"
                    />
                </Row>
                <Row className="preview">{preview()}</Row>

                <Row>
                    <Button
                        disabled={message.length < 15}
                        bsStyle="primary"
                        onClick={reviewSubmit}
                        className="float-right"
                        aria-label="submit-massText-message"
                    >
                        Send
                    </Button>
                </Row>
                <Row>
                    <Col md={2} className="selected-patients-cnt-text">
                        <span>
                            <strong>Selected Patients:</strong>
                        </span>
                    </Col>
                    <Col>
                        <i className="fas fa-fw fa-check text-success" />
                        <span>
                            Sending to&nbsp;
                            <strong>{patientArr.length > 0 ? patientArr.length : 'all'}</strong>
                            &nbsp;patients.
                        </span>
                    </Col>
                </Row>
            </div>

            <Row>
                <SearchSubscribedPatients
                    addPatients={(filteredPatientArr) => {
                        const patientSet = new Set([...patientArr, ...filteredPatientArr]);
                        setPatientArr(Array.from(patientSet));
                    }}
                />
            </Row>

            <Modal show={show} backdrop="static">
                <Modal.Header closeButton={false}>
                    <Modal.Title className="header">Send Mass Text</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preview()}
                    <Col className="modal-body-disclaimer">
                        <p>
                            Are you sure you want to send this mass text to
                            <strong>
                                {' '}
                                {patientArr.length > 0 ? patientArr.length : 'all'}&nbsp; subscribed patients?
                            </strong>
                        </p>
                        <p>
                            <strong>This will send immediately.</strong>
                        </p>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <FormButtonGroup>
                        <Button
                            bsStyle="default"
                            name="cancel"
                            className="float-right"
                            onClick={cancel}
                            aria-label="cancel-submit-massText-message"
                        >
                            Cancel
                        </Button>
                        <LaddaButton
                            bsStyle="primary"
                            name="send"
                            className="float-right"
                            onClick={send}
                            disabled={isLoading}
                            isLoading={isLoading}
                            aria-label="confirm-submit-massText-message"
                        >
                            Send
                        </LaddaButton>
                    </FormButtonGroup>
                </Modal.Footer>
            </Modal>
        </Main>
    );
}

MassTexting.prototype = propTypes;
MassTexting.defaultProps = defaultProps;

export default MassTexting;
