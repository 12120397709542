import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import { Col, FormControl, Button, Modal, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import * as Yup from 'yup';
import * as R from 'ramda';
import SubmitButton from '@/lib/form/SubmitButton';
import FormButtonGroup from '@/app/components/styles/FormButtonGroup';
import SelectField from '@/lib/form/SelectField';
import FieldLabel from '@/lib/form/FieldLabel';
import { TextField } from '@/lib/form/TextField';
import FlexCenter from '@/app/components/styles/FlexCenter';
import useActivityTextScheduleMutation from '@/app/hooks/useActivityTextScheduleMutation';
import useActivityTextScheduleDeleteMutation from '@/app/hooks/useActivityTextScheduleDeleteMutation';

const propTypes = {
    textScheduleData: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
        schedule: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string,
                select: PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                }),
            })
        ),
    }),
    onClose: PropTypes.func.isRequired,
};

const defaultProps = {
    textScheduleData: null,
};

const options = [
    { value: 'before', label: 'before' },
    { value: 'after', label: 'after' },
];

// components
const PaddedRow = styled(Row)`
    padding: 0.5% 0;
`;

const StyledFlex = styled(FlexCenter)`
    height: 100%;
    justify-content: start;
`;

const StyledLabel = styled.label`
    white-space: pre;
    margin-right: 5px;
    margin-bottom: '0px';
`;

const StyledFormControl = styled(FormControl)`
    margin-right: 5px;
`;

const TextScheduleModal = ({ textScheduleData, onClose }) => {
    const textScheduleMutation = useActivityTextScheduleMutation(textScheduleData?.uuid);
    const textScheduleDeleteMutation = useActivityTextScheduleDeleteMutation(textScheduleData?.uuid);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').max(255),
        schedule: Yup.array().of(
            Yup.object().shape({
                text: Yup.number().test('conditional-validation', 'Invalid Text', function validateText(value) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/no-this-in-sfc
                    return this.options.index === 0
                        ? Yup.number().required('First Text is required').min(0).max(127).isValidSync(value)
                        : Yup.number().min(0).max(127).isValidSync(value);
                }),
                select: Yup.object(),
            })
        ),
    });

    const onSubmit = async ({ name, schedule }, { setSubmitting }) => {
        const feildToMap = ['firstText', 'secondText', 'thirdText', 'fourthText'];
        const result = { name };
        feildToMap.forEach((field, index) => {
            if (index < schedule.length) {
                const item = schedule[index];
                if (item.select.value === 'before') {
                    result[field] = Number(item.text);
                } else {
                    result[field] = -item.text;
                }
            } else {
                result[field] = null;
            }
        });
        try {
            setSubmitting(true);
            await textScheduleMutation.mutateAsync(result);
            onClose();
        } catch (error) {
            setSubmitting(false);
        }
    };

    const onDelete = () => {
        textScheduleDeleteMutation.mutateAsync().then(() => onClose(textScheduleData?.uuid));
    };

    return (
        <Modal data-testid="textScheduleModal" show={textScheduleData !== null} backdrop="static">
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">Text Schedule</Modal.Title>
            </Modal.Header>

            {textScheduleData !== null && (
                <Formik
                    initialValues={{ name: textScheduleData?.name, schedule: textScheduleData?.schedule }}
                    validateOnMount
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, setFieldValue, values }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <PaddedRow>
                                        <Col md={12}>
                                            <p>
                                                Use this form to create a custom text schedule for activities. Each
                                                schedule can include up to four (4) texts.
                                            </p>
                                        </Col>
                                        <Col md={12}>
                                            <FieldLabel
                                                fieldName="name"
                                                label="Schedule Name"
                                                required
                                                hasError={false}
                                            >
                                                <TextField
                                                    id="name"
                                                    fieldName="name"
                                                    value={values.name}
                                                    onChange={(e) => setFieldValue('name', e.target.value)}
                                                    placeholder="Schedule Name"
                                                />
                                            </FieldLabel>
                                        </Col>
                                        <Col md={12}>
                                            <strong>
                                                Schedule<span className="text-danger">*</span>
                                            </strong>
                                        </Col>
                                    </PaddedRow>
                                    <FieldArray name="schedule">
                                        {({ remove, push }) => (
                                            <>
                                                {values.schedule.map((item, index) => {
                                                    return (
                                                        <PaddedRow
                                                            style={{ display: 'flex', alignItems: 'stretch' }}
                                                            // TODO: Fix this the next time the file is edited.
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={index}
                                                        >
                                                            <Col md={4} style={{ paddingRight: '0px' }}>
                                                                <FlexCenter>
                                                                    <StyledLabel htmlFor={`schedule.${index}.text`}>
                                                                        Text sent
                                                                    </StyledLabel>
                                                                    <StyledFormControl
                                                                        as="input"
                                                                        id={`schedule.${index}.text`}
                                                                        name={`schedule.${index}.text`}
                                                                        label={`schedule.${index}.text`}
                                                                        data-testid={`schedule.${index}.text`}
                                                                        type="text"
                                                                        placeholder="#"
                                                                        autoComplete="off"
                                                                        value={item.text}
                                                                        onKeyPress={(e) => {
                                                                            const charCode = e.key;
                                                                            if (!/[0-9]/.test(charCode)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={(e) =>
                                                                            setFieldValue(
                                                                                `schedule.${index}.text`,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </FlexCenter>
                                                            </Col>
                                                            <Col md={4} style={{ padding: '0px' }}>
                                                                <FlexCenter>
                                                                    <StyledLabel htmlFor={`schedule.${index}.select`}>
                                                                        days
                                                                    </StyledLabel>
                                                                    <SelectField
                                                                        options={options}
                                                                        id={`schedule.${index}.select`}
                                                                        name={`schedule.${index}.select`}
                                                                        data-testid={`schedule.${index}.select`}
                                                                        fieldName={`schedule.${index}.select`}
                                                                        getOptionValue={R.prop('value')}
                                                                        getOptionLabel={R.prop('label')}
                                                                        onChange={(option) =>
                                                                            setFieldValue(
                                                                                `schedule.${index}.select`,
                                                                                option
                                                                            )
                                                                        }
                                                                    />
                                                                </FlexCenter>
                                                            </Col>
                                                            <Col md={4} style={{ padding: '0px' }}>
                                                                <StyledFlex>
                                                                    <span style={{ paddingLeft: '5px' }}>
                                                                        {' '}
                                                                        activity due date
                                                                    </span>
                                                                    {index !== 0 && (
                                                                        <Button
                                                                            bsStyle="link"
                                                                            bsSize="sm"
                                                                            aria-label="Delete"
                                                                            title="Delete"
                                                                            data-testid={`deletebtn${index}`}
                                                                            onClick={() => remove(index)}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    )}
                                                                </StyledFlex>
                                                            </Col>
                                                        </PaddedRow>
                                                    );
                                                })}
                                                {values.schedule.length < 4 && (
                                                    <Row>
                                                        <Button
                                                            bsStyle="link"
                                                            bsSize="sm"
                                                            aria-label="Add Schedule"
                                                            title="Add Schedule"
                                                            onClick={() => push({ text: '', select: options[0] })}
                                                            data-testid="addSchedule"
                                                        >
                                                            <FaPlus /> Add Schedule
                                                        </Button>
                                                    </Row>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </Modal.Body>
                                <Modal.Footer>
                                    <FormButtonGroup>
                                        {textScheduleData?.uuid && (
                                            <Button
                                                name="delete"
                                                className="float-right"
                                                disabled={textScheduleDeleteMutation.isLoading}
                                                bsStyle="danger"
                                                onClick={onDelete}
                                                data-testid="textScheduleDeleteBtn"
                                            >
                                                Delete
                                            </Button>
                                        )}
                                        <Button
                                            bsStyle="default"
                                            name="cancel"
                                            className="float-right"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                        <SubmitButton>Save</SubmitButton>
                                    </FormButtonGroup>
                                </Modal.Footer>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </Modal>
    );
};

TextScheduleModal.propTypes = propTypes;
TextScheduleModal.defaultProps = defaultProps;

export default TextScheduleModal;
