const fieldOptionsLookup = {
    patient_id: 'Patient ID',
    patient_name: 'Patient Name',
    medication: 'Medication',
    activity_due_date: 'Activity Due Date',
    activity_needs_by_date: 'Activity Needs By Date',
    activity_name: 'Activity Name',
    patient_dob: 'Patient DOB',
    activity_status: 'Activity Status',
    last_updated: 'Last Updated',
    assigned_to: 'Assigned To',
    patient_labels: 'Patient Labels',
    patient_state: 'Patient State',
    branch_name: 'Branch Name',
    insurance_plan_name: 'Insurance Plan Name',
    patient_status: 'Patient Status',
    activity_status_date: 'Activity Status Last Updated Date',
    medication_association: 'Medication Association',
};

const fieldOptionsList = Object.entries(fieldOptionsLookup).map(([value, label]) => ({ value, label }));

export { fieldOptionsLookup, fieldOptionsList };
