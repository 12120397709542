import { useQuery } from 'react-query';

import { getReferralActivityBiPlan } from 'App/services/RMSService';

function useFetchPatientBIInsurance(patientId, referralId, benefitsInvestigationId) {
    return useQuery(
        ['patient', patientId, 'biInsurance'],
        () => {
            if (!patientId || !referralId || !benefitsInvestigationId) {
                return;
            }
            return getReferralActivityBiPlan(patientId, referralId, benefitsInvestigationId);
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchPatientBIInsurance;
