import React, { useMemo } from 'react';
import Col from 'react-bootstrap/lib/Col';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import SelectField from 'Lib/form/SelectField';
import { addReferralActivityBiPlan } from 'App/services/RMSService';
import { uniqBy } from 'lodash';
import { queryClient } from 'Lib/queryClient';

const attachNewPlan = '+add new plan';

function AttachInsurancePlan() {
    const { patientId, referralId, benefitsInvestigationId: activityId } = useParams();

    const { values, setFieldValue } = useFormikContext();
    const { allActiveInsurance, disableInputs, patientInsurance } = values;
    const addInsurancePlanOptions = useMemo(() => {
        const options = [
            {
                label: '+Add New Plan',
                value: attachNewPlan,
            },
        ];

        const piIds = patientInsurance.map((it) => it.uuid);
        const diffList = allActiveInsurance.filter((it) => !piIds.includes(it.uuid));
        const insuranceDifference = uniqBy(diffList, 'uuid');
        if (insuranceDifference) {
            insuranceDifference.forEach((it) => {
                options.push({
                    label: it.name,
                    value: it.id,
                });
            });
        }
        return options;
    }, [patientInsurance, allActiveInsurance]);

    const onChangeHandler = ({ value: selectOption }) => {
        setFieldValue('attachInsurance', null);
        if (selectOption === attachNewPlan) {
            let max = 0;
            for (const item of [...patientInsurance, ...allActiveInsurance]) {
                if (item.position >= max) {
                    max = item.position;
                }
            }
            setFieldValue('patientInsurance', [
                ...patientInsurance,
                {
                    position: max + 1,
                },
            ]);
        } else {
            const selectedInsurance = (allActiveInsurance || []).find((it) => it.id === selectOption);
            if (selectedInsurance) {
                setFieldValue('patientInsurance', [...patientInsurance, selectedInsurance]);
                addReferralActivityBiPlan(
                    patientId,
                    [selectedInsurance.uuid],
                    parseInt(referralId, 10),
                    parseInt(activityId, 10)
                ).then(() => {
                    queryClient.invalidateQueries(['patient', patientId, 'insurance']);
                    queryClient.invalidateQueries(['patient', patientId, 'biInsurance']);
                });
            }
        }
    };

    return (
        <Row>
            <Col md={3}>
                <SelectField
                    className="form-control insurance-field"
                    name="attachInsurance"
                    fieldName="attachInsurance"
                    label="Attach Insurance Plan(s)"
                    options={addInsurancePlanOptions}
                    onChange={onChangeHandler}
                    isClearable
                    isDisabled={disableInputs}
                />
            </Col>
        </Row>
    );
}

export default AttachInsurancePlan;
