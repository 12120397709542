import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import Select from 'react-select';

import asFormField from './asFormField';

export const SelectInput = ({
    name,
    value,
    onChange,
    onBlur,
    onInputChange,
    className,
    style,

    options,
    getOptionValue,
    getOptionLabel,
    placeholder,
    isClearable,
    isLoading,
    disabled,
    isDisabled,
    isMulti,
    addonButton,
    ...rest
}) => {
    const controlContent = (
        <Select
            {...rest}
            inputId={name}
            data-testid={`SelectInput-${name}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onInputChange={onInputChange}
            className={cx('form-control react-select', className)}
            style={style}
            // classNamePrefix is necessary for automated tests
            classNamePrefix="react-select"
            options={options}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            placeholder={placeholder}
            isClearable={isClearable}
            isLoading={isLoading}
            isDisabled={disabled || isDisabled}
            isMulti={isMulti}
        />
    );

    if (addonButton) {
        return (
            <InputGroup className="react-select-input-group">
                {controlContent}
                <InputGroup.Button>{addonButton}</InputGroup.Button>
            </InputGroup>
        );
    }

    return controlContent;
};

SelectInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onInputChange: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    addonButton: PropTypes.node,
};

SelectInput.defaultProps = {
    value: null,
    onBlur: null,
    onInputChange: null,
    className: '',
    style: null,

    getOptionValue: undefined,
    getOptionLabel: undefined,
    placeholder: undefined,
    isClearable: false,
    isLoading: false,
    disabled: false,
    isDisabled: false,
    isMulti: false,
    addonButton: null,
};

const SelectField = asFormField({ WrappedComponent: SelectInput });

export default SelectField;
