/** @ngInject */
function WorkQueueDateRangeFilterController(
    $q,
    $rootScope,
    $scope,
    $state,
    $timeout,
    moment,
    sessionStorageService,
    workQueueService,
    CSRF_ID_VALUE,
    CSRF_ID_NAME
) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeFilter = doChangeFilter;

    $scope.$on('updateWorkQueueCounts', updateWorkQueueCounts);

    const rangeType = sessionStorageService.getOnUser(`workQueue_selectedFilter`, true);

    if (rangeType && rangeType.dateRange) {
        ctrl.dateRange = rangeType.dateRange;
    } else {
        ctrl.dateRange = 'this_week';
    }

    ctrl.dateStart = new Date(moment().startOf('isoWeek').format('YYYY-MM-DD'));
    ctrl.dateEnd = new Date(moment().endOf('isoWeek').format('YYYY-MM-DD'));
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    ctrl.dateRanges = [
        {
            name: 'Overdue',
            value: 'overdue',
        },
        {
            name: 'This Week',
            value: 'this_week',
        },
        {
            name: 'Today',
            value: 'today',
        },
        {
            name: 'Next Week',
            value: 'next_week',
        },
        {
            name: 'In 2 Weeks',
            value: 'week_after',
        },
        {
            name: 'Within a Year',
            value: 'all_pending_activities',
        },
        {
            name: 'Custom',
            value: 'custom',
        },
    ];

    function $onChanges(changes) {
        if (changes.dateEnd && ctrl.dateEnd) {
            ctrl.dateEnd = new Date(moment(ctrl.dateEnd));
        }

        if (changes.dateRange && ctrl.dateRange) {
            ctrl.dateRange = angular.copy(ctrl.dateRange);
        }

        if (changes.dateStart && ctrl.dateStart) {
            ctrl.dateStart = new Date(moment(ctrl.dateStart));
        }
    }

    function $onInit() {
        workQueueService.getCounts().then((res) => {
            ctrl.activityCounts = angular.copy(res);
        });
    }

    function getDateValue(date) {
        return date ? moment(date).format('YYYY-MM-DD') : null;
    }

    function doChangeFilter() {
        $state.go($state.current.name, { dateRange: ctrl.dateRange });

        ctrl.onChangeFilter({
            filter: {
                dateEnd: getDateValue(ctrl.dateEnd),
                dateRange: ctrl.dateRange,
                dateStart: getDateValue(ctrl.dateStart),
            },
        });
    }

    function updateWorkQueueCounts(event, options) {
        return workQueueService.getCounts(options).then((res) => {
            ctrl.activityCounts = angular.copy(res);
        });
    }
}

export default WorkQueueDateRangeFilterController;
