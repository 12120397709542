import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import CustomMenuField from 'Lib/form/CustomMenu';
import { TextareaField } from 'Lib/form/TextareaField';
import { activityPropType } from './types';

const maxLength = 2500;

const NewSummaryNote = ({ activity }) => {
    const { setFieldValue } = useFormikContext();
    const isActivityComplete = activity.status === 1;
    const isActivityDeleted = activity.status === 2;
    // Keep internal state to optimize rerenders
    const [internalValue, setInternalValue] = useState('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleChange = useCallback(
        debounce((newValue) => setFieldValue('newSummaryNote', newValue), 300),
        []
    );

    const handleChange = (e) => {
        const newValue = e.target.value;
        setInternalValue(newValue);
        debouncedHandleChange(newValue);
    };

    return (
        <div>
            <h4>Summary Note:</h4>
            {activity.assessment.settings?.settings.enhancedSummaryNote && (
                <Row>
                    <Col md={6}>
                        <CustomMenuField
                            fieldName="newSummaryNoteClinicalIssues"
                            label="Clinical issues identified"
                            menuName="activity_clinical_issues"
                            isMulti
                            isDisabled={isActivityComplete || isActivityDeleted}
                        />
                    </Col>
                </Row>
            )}
            <TextareaField
                fieldName="newSummaryNote"
                aria-label="Summary Note"
                maxLength={maxLength}
                disabled={isActivityComplete || isActivityDeleted}
                value={internalValue}
                onChange={handleChange}
            />
            <div className="text-right">
                {internalValue.length}/{maxLength}
            </div>
        </div>
    );
};

NewSummaryNote.propTypes = {
    activity: activityPropType.isRequired,
};

export default NewSummaryNote;
