import { clone, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

import asFormField from './asFormField';

export const TextInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    placeholder,
    disabled,
    addonButton,
    debounce: enableDebounce,
    ...rest
}) => {
    // Keep internal state to debounce & optimize rerenders
    const [internalValue, setInternalValue] = useState(value);
    // If external state updated, then update internal state
    useEffect(() => setInternalValue(value), [value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(debounce(onChange, 200), [onChange]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setInternalValue(newValue);
        const changeFn = enableDebounce ? debouncedOnChange : onChange;
        changeFn(clone(e));
    };

    const formControlContent = (
        <FormControl
            {...rest}
            id={name}
            name={name}
            value={internalValue || ''}
            onChange={handleChange}
            onBlur={onBlur}
            className={className}
            style={style}
            type="text"
            autoComplete="off"
            placeholder={placeholder}
            disabled={disabled}
        />
    );

    if (addonButton) {
        return (
            <InputGroup>
                {formControlContent}
                <InputGroup.Button>{addonButton}</InputGroup.Button>
            </InputGroup>
        );
    }

    return formControlContent;
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    addonButton: PropTypes.node,
    debounce: PropTypes.bool,
};

TextInput.defaultProps = {
    value: '',
    onBlur: null,
    className: undefined,
    style: null,

    placeholder: undefined,
    disabled: false,
    addonButton: null,
    debounce: false,
};

/**
 * `Field` components like TextField below can ONLY be used within a Formik context.
 * For uses outside of Formik, use the raw TextInput component.
 */
export const TextField = asFormField({ WrappedComponent: TextInput });
