import React, { useMemo, useState } from 'react';
import Page from 'App/components/Page/Page';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import useFetchActivityDetails from 'App/hooks/useFetchActivityDetails';
import useFetchPatient from 'App/hooks/useFetchPatient';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useParams, generatePath, useHistory } from 'react-router-dom';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { NotFound } from 'App/components/errors';
import useUpdateReferralActivityMutation from 'App/hooks/useUpdateReferralActivityMutation';
import useUpdateReferralActivityMedicationMutation from 'App/hooks/useUpdateReferralActivityMedicationMutation';
import { errorHandler } from 'App/utils';
import { pick } from 'lodash';
import { patientRoute, referralDetailRoute } from 'App/common/routeLookup';
import toast from 'Lib/toast';
import DeleteModal from 'App/components/DeleteModal/DeleteModal';
import useDeleteReferralActivity from 'App/hooks/useDeleteReferralActivity';
import { convertBItoSave, convertBItoView, convertInsuranceToView } from '../referral.utils';
import InformationForm from './components/InformationForm';
import MedicationTableForm from './components/MedicationTableForm';
import {
    addReferralActivityBiPlan,
    createReferralActivityNote,
    deleteReferralActivityBiPlan,
} from 'App/services/RMSService';
import PESService from 'App/services/PESService';
import useFetchPatientInsurance from 'App/hooks/useFetchPatientInsurance';
import SubmitButton from 'Lib/form/SubmitButton';
import PatientHeader from 'App/components/PatientHeader';
import BenefitsInvestigationInsuranceForm from './components/BenefitsInvestigationInsuranceForm';
import useFetchPatientBIInsurance from 'App/hooks/useFetchPatientBIInsurance';

const propTypes = {};
const defaultProps = {};

const validationSchema = yup.object().shape({
    status: yup.string().required(),
    details: yup.object().shape({
        paNumber: yup.string().max(64).nullable(),
        followUpPhone: yup.string().max(20).nullable(),
        effectiveStartDate: yup.date().nullable(),
        effectiveEndDate: yup.date().nullable(),
    }),
});

function BenefitsInvestigation() {
    const { patientId, referralId, benefitsInvestigationId, typeId } = useParams();
    const history = useHistory();
    const { isLoading, isError, data: patient } = useFetchPatient(patientId);
    const { data: activityDetails } = useFetchActivityDetails(patientId, referralId, benefitsInvestigationId, typeId);
    const updateReferralActivity = useUpdateReferralActivityMutation();
    const updateReferralActivityMedication = useUpdateReferralActivityMedicationMutation();
    const navigateToReferralDetailPage = () => {
        history.push(generatePath(referralDetailRoute, { patientId, referralId }));
    };
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const deleteReferralActivity = useDeleteReferralActivity();
    const { data: insurance } = useFetchPatientInsurance(patientId);
    const { data: biInsurance, isLoading: isInsuranceLoading } = useFetchPatientBIInsurance(
        patientId,
        +referralId,
        +benefitsInvestigationId
    );

    const deleteActivity = () => {
        deleteReferralActivity
            .mutateAsync({
                patientId,
                referralId,
                activityId: benefitsInvestigationId,
            })
            .then(() => {
                navigateToReferralDetailPage();
            })
            .catch(errorHandler);
    };

    const updatePatientInsurance = (insurancePayload) => {
        if (insurancePayload.length > 0) {
            const promiseArray = insurancePayload.map((item) => {
                // Insurance already exist
                if (item.id) {
                    // this insurance was already assigned to BI plan ( this is a new patient insurance)
                    if (item.uuid) {
                        return PESService.updatePatientInsurance(patientId, item.id, item);
                    }
                    // The else part is no more required
                    // else (for old patient insurance select from the dropdown)
                    // -> Update the BI plan insurance (RMS old API) (rmsService.updateInsurancePlan)
                    // -> Create a new patient insurance ( we get the uuid here)
                    // -> update the patient uuid (RMS old API) (rmsService.updateInsurancePlan)
                }

                // New Insurance
                // -> save the patient insurance -> get the UUID
                // -> Add it to the BI plan using UUID
                let patientInsuranceUuids = [];
                return PESService.addPatientInsurance(patientId, {
                    insurance: [item],
                })
                    .then((res) => {
                        const insuranceKeys = Object.keys(res);
                        patientInsuranceUuids = insuranceKeys.map((it) => {
                            return res[it].uuid;
                        });
                    })
                    .then(() => {
                        return addReferralActivityBiPlan(
                            patientId,
                            patientInsuranceUuids,
                            parseInt(referralId, 10),
                            parseInt(benefitsInvestigationId, 10)
                        );
                    });
            });
            return Promise.all(promiseArray);
        }
    };

    const removeBIInsurance = (insuranceToRemove) => {
        if (insuranceToRemove.length) {
            const referralActivityBiPlanPromises = insuranceToRemove.map((item) =>
                deleteReferralActivityBiPlan(
                    patientId,
                    +referralId,
                    +benefitsInvestigationId,
                    item.patientInsuranceUuid
                )
            );

            return Promise.all(referralActivityBiPlanPromises);
        }
        return Promise.resolve();
    };

    const initialValues = useMemo(() => {
        return {
            ...(activityDetails ? convertBItoView(activityDetails) : {}),
            patientInsurance: [],
            allActiveInsurance: [],
        };
    }, [activityDetails]);

    if (isLoading || isInsuranceLoading) {
        return <TherigyLoadingSpinner />;
    }

    if (isError) {
        return (
            <Page
                title="Not Found"
                showPageTitle={false}
                permissionType={PERMISSION_TYPES.ENABLE_REACT_REFERRAL_BENEFITS_INVESTIGATION}
            >
                <NotFound />
            </Page>
        );
    }

    return (
        <Page
            title="Benefits Investigation"
            permissionType={PERMISSION_TYPES.ENABLE_REACT_REFERRAL_BENEFITS_INVESTIGATION}
        >
            <div className="container page-wrapper">
                <Row>
                    <Col md={12}>
                        <PatientHeader patient={patient} showEhrStatus={false} />
                    </Col>
                </Row>
                <nav className="profile-actions">
                    <ul className="list-inline">
                        <li>
                            <Link to={generatePath(patientRoute, { patientId })}>Back to Patient Profile</Link>
                        </li>
                        <li>
                            <Link to={generatePath(referralDetailRoute, { patientId, referralId })}>
                                Back to Referral
                            </Link>
                        </li>
                    </ul>
                </nav>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        const formattedInsurance = convertInsuranceToView(insurance);
                        const {
                            medicationPayload,
                            notesPayload,
                            insurancePayload,
                            activityPayload,
                            removeInsurancePayload,
                        } = convertBItoSave(values, formattedInsurance, biInsurance);
                        const medicationPromiseArray = medicationPayload.map((med) =>
                            updateReferralActivityMedication.mutateAsync({
                                patientId,
                                referralId,
                                activityId: benefitsInvestigationId,
                                medicationId: med.id,
                                data: pick(med, ['medication_id', 'data']),
                            })
                        );
                        return Promise.all(medicationPromiseArray)
                            .then(() => createReferralActivityNote(benefitsInvestigationId, notesPayload))
                            .then(() => updatePatientInsurance(insurancePayload))
                            .then(() => removeBIInsurance(removeInsurancePayload))
                            .then(() =>
                                updateReferralActivity.mutateAsync({
                                    patientId,
                                    referralId,
                                    activityId: benefitsInvestigationId,
                                    data: activityPayload,
                                })
                            )
                            .then(() => {
                                toast.success('Activity Information has been updated successfully');
                                navigateToReferralDetailPage();
                            })
                            .catch(errorHandler);
                    }}
                >
                    {({ isSubmitting, handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <InformationForm />
                                <MedicationTableForm />
                                <BenefitsInvestigationInsuranceForm insurance={insurance} biInsurance={biInsurance} />
                                <FormButtonGroup>
                                    <Button
                                        bsClass="btn btn-danger"
                                        onClick={() => setShowDeleteModal(true)}
                                        disabled={isSubmitting || values.disableInputs}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        disabled={isSubmitting}
                                        bsClass="btn btn-default"
                                        onClick={navigateToReferralDetailPage}
                                    >
                                        Cancel
                                    </Button>
                                    <SubmitButton
                                        bsClass="btn btn-primary"
                                        id="benefits-investigation-submit"
                                        disabled={isSubmitting || values?.disableInputs}
                                    >
                                        Save Activity
                                    </SubmitButton>
                                </FormButtonGroup>
                            </form>
                        );
                    }}
                </Formik>
            </div>
            {showDeleteModal && (
                <DeleteModal
                    onHide={() => setShowDeleteModal(false)}
                    show={showDeleteModal}
                    onDelete={deleteActivity}
                    title="Delete Activity"
                    message="Are you sure you want to delete this activity?"
                />
            )}
        </Page>
    );
}

BenefitsInvestigation.propTypes = propTypes;
BenefitsInvestigation.defaultProps = defaultProps;

export default BenefitsInvestigation;
