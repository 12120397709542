import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import PropTypes from 'prop-types';

import NavTabs from 'App/components/NavTabs/NavTabs';
import InsuranceFormArea from 'App/components/PatientInsurance/InsuranceFormArea';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';

import AddInsuranceDetachModal from 'App/components/PatientInsurance/AddInsuranceDetachModal';
import SectionTitleStyle from 'App/components/PatientForm/components/SectionTitleStyle';
import AttachInsurancePlan from './AttachInsurancePlan';
import { computePatientInsurance, convertBiInsuranceToView, convertInsuranceToView } from '../../referral.utils';

const BenefitsInvestigationInsuranceForm = ({ insurance, biInsurance }) => {
    const { values, setFieldValue, validateForm } = useFormikContext();
    const [removeIndex, setRemoveIndex] = useState();
    const [showDetachModal, setShowDetachModal] = useState(false);

    useEffect(() => {
        const formattedInsurance = convertInsuranceToView(insurance);
        const formattedBiInsurance = convertBiInsuranceToView(biInsurance);
        const { allActiveInsurance, patientInsurance } = computePatientInsurance(
            formattedInsurance,
            formattedBiInsurance
        );

        setFieldValue('allActiveInsurance', allActiveInsurance);
        setFieldValue('patientInsurance', patientInsurance);
    }, [insurance, biInsurance, setFieldValue]);

    const removeInsurancePlan = () => {
        values.patientInsurance.splice(removeIndex, 1);
        setFieldValue('patientInsurance', [...values.patientInsurance]);
        setRemoveIndex(undefined);
        setImmediate(() => validateForm());
    };

    const onRemoveInsurancePlanClick = (index) => {
        setRemoveIndex(index);
        setShowDetachModal(true);
    };

    return (
        <>
            <SectionTitleStyle>
                <h2>Insurance Plans</h2>
            </SectionTitleStyle>

            <AttachInsurancePlan />
            {!values.patientInsurance.length ? (
                <TherigyInfoMessage message="There are no insurance plans attached to this patient." />
            ) : (
                <NavTabs
                    items={values.patientInsurance}
                    renderNavTab={(item, index) => (
                        <>
                            <span>{item.name || `Plan ${item.position}`} </span>
                            {!values.disableInputs && (
                                <FaWindowClose
                                    onClick={() => {
                                        onRemoveInsurancePlanClick(index);
                                    }}
                                />
                            )}
                        </>
                    )}
                    renderPaneContent={(item, index) => (
                        <InsuranceFormArea fieldPrefix={`patientInsurance[${index}]`} isBenefitsInsurance />
                    )}
                />
            )}
            {showDetachModal && (
                <AddInsuranceDetachModal
                    detach={showDetachModal}
                    setDetach={setShowDetachModal}
                    removePlan={removeInsurancePlan}
                />
            )}
        </>
    );
};

BenefitsInvestigationInsuranceForm.propTypes = {
    insurance: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string.isRequired,
            type: PropTypes.string,
            status: PropTypes.string,
        })
    ).isRequired,
    biInsurance: PropTypes.arrayOf(
        PropTypes.shape({
            patientInsuranceUuid: PropTypes.string.isRequired,
            insuranceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string.isRequired,
            type: PropTypes.string,
        })
    ).isRequired,
};

export default BenefitsInvestigationInsuranceForm;
