import styled from '@emotion/styled';
import { Risk } from '@stm/arm';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React from 'react';

import SafeHtmlOnly from '../SafeHtmlOnly/SafeHtmlOnly';
import { rapid3FunctionalScoringIds, rapid3TotalScoringIds } from './constants';
import { activityPropType } from './types';

const AssessmentScoringStyle = styled.div`
    color: var(--brandInfo);
    font-weight: bold;
`;

const AssessmentScoreStyle = styled.div`
    padding-left: 0.75em;
    padding-bottom: 0.75em;
`;

const AssessmentScoringValueStyle = styled.span`
    color: var(--brandDanger);
`;

const AssessmentScoring = ({ activity }) => {
    const { values } = useFormikContext();
    const { scoring } = activity.assessment;
    const modifiedValues = Object.values(values).map((questionValue) => ({
        ...questionValue,
        response: questionValue.response?.id || questionValue.response,
    }));
    const scores = Risk.calculate(scoring, modifiedValues);
    const followUpBaseDate = dayjs(activity.status === 1 && activity.completedOn ? activity.completedOn : undefined);

    return (
        <AssessmentScoringStyle>
            <div>Assessment Score</div>
            {Object.entries(scores)
                .filter(
                    ([scoreId]) =>
                        !rapid3TotalScoringIds.includes(scoreId) && !rapid3FunctionalScoringIds.includes(scoreId)
                )
                .map(([scoreId, score]) => {
                    const scoringDefinition = scoring[scoreId];
                    const followUpDate = followUpBaseDate.add(score.followUp, 'days');
                    return (
                        <AssessmentScoreStyle key={scoreId}>
                            <div>
                                Name:{' '}
                                <AssessmentScoringValueStyle>{scoringDefinition.riskName}</AssessmentScoringValueStyle>
                            </div>
                            <div>
                                Description:{' '}
                                <AssessmentScoringValueStyle>
                                    <SafeHtmlOnly>{scoringDefinition.riskDescription}</SafeHtmlOnly>
                                </AssessmentScoringValueStyle>
                            </div>
                            <div>
                                Score Value: <AssessmentScoringValueStyle>{score.value}</AssessmentScoringValueStyle>
                            </div>
                            <div>
                                Score Stratification:{' '}
                                <AssessmentScoringValueStyle>{score.stratification}</AssessmentScoringValueStyle>
                            </div>
                            <div>
                                Recommended Follow-Up:{' '}
                                <AssessmentScoringValueStyle>
                                    {followUpDate.format('YYYY/MM/DD')}
                                </AssessmentScoringValueStyle>
                            </div>
                        </AssessmentScoreStyle>
                    );
                })}
        </AssessmentScoringStyle>
    );
};

AssessmentScoring.propTypes = {
    activity: activityPropType.isRequired,
};

export default AssessmentScoring;
