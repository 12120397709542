/**
 * TODO: This component is doing a little too much. We should consider extracting the `renderQuestionBaseField` function
 * out into individual components to correctly separate the concerns. It's probably okay to keep this component as a
 * "controller" of sorts to choose which one to render.
 */
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { get, merge, set } from 'lodash';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

import { getActivityQuestionsQueryKey } from 'App/features/Activity/hooks/useFetchActivityQuestions';
import { getActivityQuestionsById, getAssessmentQuestionsById, saveResponse } from 'App/services/AMSService';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { NumberField } from 'Lib/form/NumberField';
import { RadioField } from 'Lib/form/RadioField';
import SelectField from 'Lib/form/SelectField';
import { TextField } from 'Lib/form/TextField';
import { TextareaField } from 'Lib/form/TextareaField';
import { queryClient } from 'Lib/queryClient';
import { patientPropType } from 'Lib/types';
import SafeHtmlOnly from '../../SafeHtmlOnly/SafeHtmlOnly';
import { rapid3FunctionalScoreQuestionIds, rapid3TotalScoreQuestionIds, viewModes } from '../constants';
import { activityPropType, questionPropType } from '../types';
import convertAssessmentQuestionToView from '../utils/convertAssessmentQuestionToView';
import getBlockedDays from '../utils/getBlockedDays';
import AssessmentQuestionScoring from './AssessmentQuestionScoring';
import AssessmentQuestionText from './AssessmentQuestionText';
import AssessmentRapid3ScoreQuestion from './AssessmentRapid3ScoreQuestion';
import ConfirmDeleteChildrenModal from './ConfirmDeleteChildrenModal';
import DisplayCMSContent from './DisplayCMSContent';

const AssessmentQuestionStyle = styled.div`
    margin-bottom: 25px;

    .radio,
    .checkbox {
        margin-left: 10px;
    }

    .form-control {
        width: 300px;
    }

    textarea.form-control {
        width: 100%;
    }
`;

const StyledTooltip = styled(Tooltip)`
    .tooltip-inner {
        background-color: var(--brandPrimary);
    }

    &.tooltip.right .tooltip-arrow {
        border-right-color: var(--brandPrimary);
    }
`;

const MobileAnswerLabelStyle = styled.div`
    padding-bottom: 6px;
`;

const ChildQuestionStyle = styled.div`
    margin-left: 30px;
`;

const getOptionLabel = R.curry((mode, option) => {
    const value = option.option;
    if (mode.label === viewModes.VIEW_MOBILE) {
        return R.prop('mobile_option', option) || value;
    }
    return value;
});

const filterDates = R.curry((blockedDaysAttr, date) => {
    const blockedDays = getBlockedDays(blockedDaysAttr);
    return !blockedDays.includes(date.getDay());
});

const AssessmentQuestion = ({ question, activity, patient, isSpecialQuestion }) => {
    const { values, setValues, setSubmitting } = useFormikContext();
    const children = values[question.questionId]?.children;
    const [showDeleteChildrenModal, setShowDeleteChildrenModal] = useState(false);
    const [pendingValue, setPendingValue] = useState(null);

    const getAllChildIds = (childrenList, newValue) => {
        if (!childrenList) return [];
        return childrenList.reduce((idsAgg, currQuestion) => {
            if (currQuestion.children) {
                idsAgg.push(...getAllChildIds(currQuestion.children, newValue));
            }
            const isChildStillPresent = (newValue?.id || newValue)?.includes(currQuestion.parentAnswerId);
            if (!isChildStillPresent) {
                idsAgg.push(currQuestion.questionId);
            }
            return idsAgg;
        }, []);
    };

    const getNumberOfAnswers = (newValue) => {
        return getAllChildIds(children, newValue).reduce((agg, currId) => {
            const value = get(values, `${currId}.response`);
            if (value) {
                return agg + (Array.isArray(value) ? value.length : 1);
            }
            return agg;
        }, 0);
    };

    const getQuestionsByIds = async (ids) => {
        if (!ids.length || (ids.length === 1 && ids[0] === undefined)) return [];
        if (!activity.id) {
            return getAssessmentQuestionsById(activity.assessment.id, ids);
        }
        const newQuestions = await getActivityQuestionsById(activity.id, ids);
        const queryKey = getActivityQuestionsQueryKey(activity.id);
        queryClient.setQueryData(queryKey, { ...queryClient.getQueryData(queryKey), ...newQuestions });
        return newQuestions;
    };

    const commitSaveResponse = async ({
        value,
        activityDateQuestion,
        activityDateValue,
        needByDateQuestion,
        needByDateValue,
    }) => {
        if (!activity.id) {
            return;
        }

        setSubmitting(true);
        await saveResponse(activity.id, question.questionId, value?.id || value || '');
        if (activityDateQuestion) {
            await saveResponse(activity.id, activityDateQuestion.questionId, activityDateValue);
        }
        if (needByDateQuestion) {
            await saveResponse(activity.id, needByDateQuestion.questionId, needByDateValue);
        }
        queryClient.removeQueries(getActivityQuestionsQueryKey(activity.id));
        setSubmitting(false);
    };

    const commitChange = async (value) => {
        const childIds = getAllChildIds(children, value);
        const valueChanges = childIds.reduce((agg, currId) => set(agg, `${currId}.response`, undefined), values);
        set(valueChanges, `${question.questionId}.response`, value);
        set(valueChanges, `${question.questionId}.mobileUserResponse`, false);

        // Update values for Activity Date & Need By Date
        const valuesList = Object.values(values);
        const activityDateQuestion = valuesList.find(
            (questionValue) => questionValue.name === 'activity_date' && !questionValue.response
        );
        const activityDateValue = dayjs().toDate();
        if (activityDateQuestion) {
            set(valueChanges, `${activityDateQuestion.questionId}.response`, activityDateValue);
        }
        const needByDateQuestion = valuesList.find(
            (questionValue) => questionValue.name === 'need_by_date' && !questionValue.response
        );
        const needByDateValue = dayjs(question.name === 'activity_date' ? value : undefined)
            .add(7, 'days')
            .toDate();
        if (needByDateQuestion) {
            set(valueChanges, `${needByDateQuestion.questionId}.response`, needByDateValue);
        }

        setValues(valueChanges);

        commitSaveResponse({
            value,
            activityDateQuestion,
            activityDateValue,
            needByDateQuestion,
            needByDateValue,
        });

        await setNewChildrenValues(value);
    };

    async function setNewChildrenValues(value) {
        const answersArray = Object.values(question.answers);
        if (!answersArray.length) return;
        const fullAnswerValues = Array.isArray(value)
            ? answersArray.filter((a) => value.includes(a.id))
            : [question.answers[value?.id ?? value]];
        const allChildIds = fullAnswerValues.map((val) => val?.children).flat();
        const newChildren = await getQuestionsByIds(allChildIds);

        const newChildrenList = Object.values(newChildren).map(convertAssessmentQuestionToView);
        const valueChanges = newChildrenList.reduce((agg, child) => merge(agg, { [child.questionId]: child }), {
            ...values,
        });
        set(valueChanges, `${question.questionId}.children`, newChildrenList);
        setValues(valueChanges);
    }

    const handleChange = async (event) => {
        const value = event?.target?.value ?? event;

        const numberOfAnswers = getNumberOfAnswers(value);
        if (numberOfAnswers > 0) {
            setPendingValue(value);
            setShowDeleteChildrenModal(true);
        } else {
            commitChange(value);
        }
    };

    const handleCloseDeleteChildrenModal = (confirm) => {
        if (confirm) {
            commitChange(pendingValue);
        }
        setShowDeleteChildrenModal(false);
    };

    const renderChildrenQuestions = (answerId) => {
        return (children || [])
            .filter((child) => !answerId || child.parentAnswerId === answerId)
            .sort((childA, childB) => childA.attributes.position - childB.attributes.position)
            .map((child) => (
                <ChildQuestionStyle key={child.questionId}>
                    <AssessmentQuestion question={child} activity={activity} patient={patient} />
                </ChildQuestionStyle>
            ));
    };

    const renderQuestionBaseField = () => {
        const isTotalScoreQuestion = rapid3TotalScoreQuestionIds.includes(question.questionId);
        const isFunctionalScoreQuestion = rapid3FunctionalScoreQuestionIds.includes(question.questionId);
        if (isTotalScoreQuestion || isFunctionalScoreQuestion) {
            return (
                <AssessmentRapid3ScoreQuestion
                    activity={activity}
                    question={question}
                    isFunctionalScore={isFunctionalScoreQuestion}
                />
            );
        }

        const label = (
            <>
                <AssessmentQuestionText question={question} viewMode={values.viewMode} patient={patient} />{' '}
                {question.attributes.extra_info && (
                    <OverlayTrigger
                        overlay={
                            <StyledTooltip id={`question-tooltip-${question.questionId}`}>
                                <SafeHtmlOnly>{question.attributes.extra_info}</SafeHtmlOnly>
                            </StyledTooltip>
                        }
                    >
                        <FaInfoCircle className="text-primary" />
                    </OverlayTrigger>
                )}
            </>
        );

        const isActivityComplete = activity.status === 1;
        const isActivityDeleted = activity.status === 2;

        const commonProps = {
            fieldName: `${question.questionId}.response`,
            label,
            preInputContent: (
                <>
                    {values[question.questionId]?.mobileUserResponse && (
                        <MobileAnswerLabelStyle>
                            <Label bsStyle="success">Mobile answer</Label>
                        </MobileAnswerLabelStyle>
                    )}
                    {question.attributes.risk_config && (
                        <AssessmentQuestionScoring question={question} activity={activity} />
                    )}
                </>
            ),
            onChange: handleChange,
            disabled: isActivityComplete || isActivityDeleted,
            required: question.attributes.required,
        };
        switch (question.type) {
            case 'message':
                return label;
            case 'date': {
                const blockedDays = question.attributes?.blockedDays;
                const filterDatesFn = blockedDays ? filterDates(blockedDays) : undefined;

                return (
                    <DatePickerField
                        {...commonProps}
                        placeholder="YYYY/MM/DD"
                        filterDate={filterDatesFn}
                        onValidate={(date) => {
                            if (date && filterDatesFn) {
                                return !filterDatesFn(date);
                            }
                            return false;
                        }}
                    />
                );
            }
            case 'select':
                return (
                    <SelectField
                        {...commonProps}
                        options={Object.values(question.answers)}
                        getOptionLabel={getOptionLabel(values.viewMode)}
                        getOptionValue={R.prop('id')}
                        isClearable
                    />
                );
            case 'radio':
                return (
                    <RadioField
                        {...commonProps}
                        options={Object.values(question.answers).map((a) => ({
                            ...a,
                            option: <SafeHtmlOnly>{getOptionLabel(values.viewMode, a)}</SafeHtmlOnly>,
                        }))}
                    />
                );
            case 'checkbox': {
                return (
                    <CheckboxMultiField
                        {...commonProps}
                        options={Object.values(question.answers).map((a) => ({
                            ...a,
                            option: (
                                <DisplayCMSContent content={getOptionLabel(values.viewMode, a)} patient={patient} />
                            ),
                        }))}
                        postOptionsContent={Object.values(question.answers).map((a) => renderChildrenQuestions(a.id))}
                    />
                );
            }
            case 'textbox':
                return <TextField {...commonProps} debounce />;
            case 'number':
                return <NumberField {...commonProps} />;
            case 'textarea':
                return <TextareaField {...commonProps} debounce />;
            default:
                return null;
        }
    };

    if (question.attributes.hidden && (!question.response || question.response === 0)) {
        return null;
    }

    /**
     * ViewMode should always be set.
     * Render nothing if the view mode is mobile and this is not a mobile question.
     */
    if (!isSpecialQuestion && values.viewMode.label === viewModes.VIEW_MOBILE && !question.attributes.is_mobile) {
        return null;
    }

    return (
        <AssessmentQuestionStyle id={question.questionId}>
            {renderQuestionBaseField()}
            {question.type !== 'checkbox' && renderChildrenQuestions()}
            {showDeleteChildrenModal && (
                <ConfirmDeleteChildrenModal
                    numberOfAnswers={getNumberOfAnswers(pendingValue)}
                    onClose={handleCloseDeleteChildrenModal}
                />
            )}
        </AssessmentQuestionStyle>
    );
};

AssessmentQuestion.propTypes = {
    question: questionPropType.isRequired,
    activity: activityPropType.isRequired,
    patient: patientPropType.isRequired,
    isSpecialQuestion: PropTypes.bool,
};

AssessmentQuestion.defaultProps = {
    isSpecialQuestion: false,
};

export default AssessmentQuestion;
