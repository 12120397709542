import PropTypes from 'prop-types';

const questionPropTypeShape = {
    questionId: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    question: PropTypes.string,
    compactQuestion: PropTypes.string,
    mobileQuestion: PropTypes.string,
    parentQuestionId: PropTypes.string,
    attributes: PropTypes.shape({
        required: PropTypes.bool,
        extra_info: PropTypes.string,
        position: PropTypes.number,
        ehr_encounter_note: PropTypes.bool,
        risk_config: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        risk_weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        answerWeights: PropTypes.objectOf(PropTypes.number),
    }),
    response: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        PropTypes.number,
    ]),
    mobileUserResponse: PropTypes.bool,
};
questionPropTypeShape.children = PropTypes.arrayOf(PropTypes.shape(questionPropTypeShape));

const questionPropType = PropTypes.shape(questionPropTypeShape);

export default questionPropType;
